import React from 'react'
import style from './Footer.module.css'
import { headerLogo } from '../../Assets/Icons/Header/Header'
import { useTranslation } from 'react-i18next'
const Footer = () => {
  const { t } = useTranslation()

  const mediaLinks = [
    {
      href: 'https://www.youtube.com/channel/UCbVuVgPrdDQZgDn657tvfjw',
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="12"
          viewBox="0 0 16 12"
          fill="none"
        >
          <path
            d="M15.8406 2.80007C15.8406 2.80007 15.6844 1.69695 15.2031 1.21257C14.5938 0.575073 13.9125 0.571948 13.6 0.534448C11.3625 0.371948 8.00313 0.371948 8.00313 0.371948H7.99687C7.99687 0.371948 4.6375 0.371948 2.4 0.534448C2.0875 0.571948 1.40625 0.575073 0.796875 1.21257C0.315625 1.69695 0.1625 2.80007 0.1625 2.80007C0.1625 2.80007 0 4.09695 0 5.3907V6.6032C0 7.89695 0.159375 9.19382 0.159375 9.19382C0.159375 9.19382 0.315625 10.2969 0.79375 10.7813C1.40313 11.4188 2.20313 11.3969 2.55938 11.4657C3.84063 11.5876 8 11.6251 8 11.6251C8 11.6251 11.3625 11.6188 13.6 11.4594C13.9125 11.4219 14.5938 11.4188 15.2031 10.7813C15.6844 10.2969 15.8406 9.19382 15.8406 9.19382C15.8406 9.19382 16 7.90007 16 6.6032V5.3907C16 4.09695 15.8406 2.80007 15.8406 2.80007ZM6.34688 8.07507V3.5782L10.6687 5.83445L6.34688 8.07507Z"
            fill="white"
          />
        </svg>
      ),
    },
    {
      href: 'https://www.linkedin.com/company/prostir3d/',
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M14.8156 0H1.18125C0.528125 0 0 0.515625 0 1.15313V14.8438C0 15.4813 0.528125 16 1.18125 16H14.8156C15.4688 16 16 15.4813 16 14.8469V1.15313C16 0.515625 15.4688 0 14.8156 0ZM4.74687 13.6344H2.37188V5.99687H4.74687V13.6344ZM3.55938 4.95625C2.79688 4.95625 2.18125 4.34062 2.18125 3.58125C2.18125 2.82188 2.79688 2.20625 3.55938 2.20625C4.31875 2.20625 4.93437 2.82188 4.93437 3.58125C4.93437 4.3375 4.31875 4.95625 3.55938 4.95625ZM13.6344 13.6344H11.2625V9.92188C11.2625 9.0375 11.2469 7.89687 10.0281 7.89687C8.79375 7.89687 8.60625 8.8625 8.60625 9.85938V13.6344H6.2375V5.99687H8.5125V7.04063H8.54375C8.85938 6.44063 9.63438 5.80625 10.7875 5.80625C13.1906 5.80625 13.6344 7.3875 13.6344 9.44375V13.6344Z"
            fill="white"
          />
        </svg>
      ),
    },
    {
      href: 'https://www.facebook.com/prostir3d',
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <g clip-path="url(#clip0_97_100)">
            <path
              d="M16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 11.993 2.92547 15.3027 6.75 15.9028V10.3125H4.71875V8H6.75V6.2375C6.75 4.2325 7.94438 3.125 9.77172 3.125C10.6467 3.125 11.5625 3.28125 11.5625 3.28125V5.25H10.5538C9.56 5.25 9.25 5.86672 9.25 6.5V8H11.4688L11.1141 10.3125H9.25V15.9028C13.0745 15.3027 16 11.993 16 8Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_97_100">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ),
    },
  ]

  return (
    <div className={style.footer}>
      <div className={`${'customContainer'} ${style.footer_wrap}`}>
        <img className={style.logo} src={headerLogo} alt="Prostir3D" />
        <p className={style.privacy}>{t('footer.text')}</p>
        <div className={style.media}>
          {mediaLinks.map((link, index) => (
            <a key={index} href={link.href} target={'blank'}>
              {link.svg}
            </a>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Footer
