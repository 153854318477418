import React, { useState } from 'react'
import { PhoneInput } from 'react-international-phone'
import 'react-international-phone/style.css'
import style from './Contacts.module.css'
import { useTranslation } from 'react-i18next'

const Contacts = () => {
  const { t } = useTranslation()
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    comment: '',
    to: 'a7fe8adf.itton.onmicrosoft.com@emea.teams.ms',
  })
  const [notifications, setNotifications] = useState([])

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handlePhoneChange = (value) => {
    setFormData({ ...formData, phone: value })
  }

  const showNotification = (message) => {
    setNotifications((prev) => [...prev, message])
    setTimeout(() => {
      setNotifications((prev) => prev.filter((msg) => msg !== message))
    }, 3000)
  }

  const validateForm = () => {
    const newErrors = {}

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!emailRegex.test(formData.email)) {
      newErrors.email = t('form.alarmEmail')
      showNotification(newErrors.email)
    }

    return Object.keys(newErrors).length === 0
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!validateForm()) {
      return
    }

    try {
      const response = await fetch(
        'https://prostir3dmailer.balanceglobal.com.ua/post',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        },
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      const data = await response.json()
      console.log(data)
      reset()
      showNotification(t('form.formSend'))
    } catch (error) {
      console.error('Error sending form data:', error)
      showNotification(t('form.formdontSend'))
    }
  }

  const reset = () => {
    setFormData({
      name: '',
      phone: '',
      email: '',
      comment: '',
    })
  }

  return (
    <div className={style.contacts}>
      <div className={`${'customContainer'} ${style.form_wrap}`}>
        <h2 className={style.contacts_title}>{t('form.title')}</h2>
        <p className={style.contacts_desc}>{t('form.desc')}</p>
        {notifications.map((msg, index) => (
          <div key={index} className={style.notification}>
            {msg}
          </div>
        ))}
        <form onSubmit={handleSubmit}>
          <div className={style.inputGroup}>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              placeholder={t('form.name')}
              className={style.input}
            />
          </div>
          <div className={style.inputGroup}>
            <PhoneInput
              defaultCountry={'ua'}
              name="phone"
              value={formData.phone}
              onChange={handlePhoneChange}
              required
              placeholder={t('form.phone')}
              className={style.input}
            />
          </div>
          <div className={style.inputGroup}>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              placeholder={t('form.email')}
              className={style.input}
            />
          </div>
          <div className={style.inputGroup}>
            <input
              id="comment"
              name="comment"
              value={formData.comment}
              onChange={handleChange}
              placeholder={t('form.comment')}
              className={style.input}
            />
          </div>
          <button className={style.button} type="submit">
            {t('form.button')}
          </button>
        </form>
      </div>
    </div>
  )
}

export default Contacts
